import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Image, Carousel } from "react-bootstrap";
import "./ExampleContainer.scss";

export default function ExampleContainer() {
  return (
    <Container>
      <Row>
        {/* <Col xs={6} md={4}>
          <p className="img_name_t">Input</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/p1.png"}
            fluid
          />
        </Col> */}
        <Col xs={6} md={4}>
          <p className="img_name_r">Happy</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/lee2.png"}
            fluid
          />
        </Col>
        {/* <Col xs={6} md={4}>
          <p className="img_name_t">Korea abstract painting</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/4.png"}
            fluid
          />
        </Col> */}
        {/* <Col xs={6} md={4}>
          <p className="img_name_t">Angry</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/p1.png"}
            fluid
          />
        </Col> */}
        <Col xs={6} md={4}>
          <p className="img_name_r">Angry</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/Angry_result.jpg"}
            fluid
          />
        </Col>
        {/* <Col xs={6} md={4}>
          <p className="img_name_t">Korea abstract painting</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/2.png"}
            fluid
          />
        </Col> */}
        {/* <Col xs={6} md={4}>
          <p className="img_name_t">Neutral</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/p1.png"}
            fluid
          />
        </Col> */}
        <Col xs={6} md={4}>
          <p className="img_name_r">Neutral</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/Neutral.jpg"}
            fluid
          />
        </Col>
        {/* <Col xs={6} md={4}>
          <p className="img_name_t">Korea abstract painting</p>
          <Image
            className="example_img"
            src={process.env.PUBLIC_URL + "/Images/3.png"}
            fluid
          />
        </Col> */}
      </Row>
    </Container>
  );
}
